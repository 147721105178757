var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h1", [_vm._v("Wachtwoord instellen")]),
            _vm.loading
              ? _c(
                  "div",
                  [_c("b-spinner", { attrs: { label: "Spinning" } })],
                  1
                )
              : _vm._e(),
            _vm.loading == false && _vm.verified == false
              ? _c("div", [_vm._v(" Ongeldige reset link ")])
              : _vm._e(),
            _vm.errorMessage != ""
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" }
                  },
                  [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                )
              : _vm._e(),
            _vm.verified && !_vm.loading && !_vm.success
              ? _c("div", [
                  _c("p", { staticClass: "mt-4" }, [
                    _vm._v(
                      "Vul hieronder uw nieuwe wachtwoord van minimaal 8 karakters in:"
                    )
                  ]),
                  _c("form", [
                    _c("table", [
                      _c("tr", [
                        _c("td", [
                          _c("label", { attrs: { for: "password" } }, [
                            _vm._v("Wachtwoord")
                          ])
                        ]),
                        _c(
                          "td",
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "password",
                                type: "password",
                                required: "",
                                autofocus: ""
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("td", [
                          _c("label", { attrs: { for: "password" } }, [
                            _vm._v("Herhaal wachtwoord")
                          ])
                        ]),
                        _c(
                          "td",
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "password_check",
                                type: "password",
                                required: ""
                              },
                              model: {
                                value: _vm.passwordCheck,
                                callback: function($$v) {
                                  _vm.passwordCheck = $$v
                                },
                                expression: "passwordCheck"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("td"),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: !_vm.valid(),
                                  variant: "primary",
                                  type: "submit"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleSubmit($event)
                                  }
                                }
                              },
                              [_vm._v("Instellen")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.success
              ? _c(
                  "div",
                  [
                    _vm._v(" Het aanpassen van het wachtwoord is gelukt. "),
                    _c("router-link", { attrs: { to: { name: "login" } } }, [
                      _vm._v(" Je kunt nu hier inloggen. ")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }