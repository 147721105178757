<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>Wachtwoord instellen</h1>

        <div v-if="loading">
          <b-spinner label="Spinning"></b-spinner>
        </div>

        <div v-if="loading == false && verified == false">
          Ongeldige reset link
        </div>

        <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
          {{ errorMessage }}
        </div>

        <div v-if="verified && !loading && !success">
          <p class="mt-4">Vul hieronder uw nieuwe wachtwoord van minimaal 8 karakters in:</p>

          <form>
            <table>
              <tr>
                <td><label for="password">Wachtwoord</label></td>
                <td><b-form-input id="password" type="password" v-model="password" required autofocus /></td>
              </tr>
              <tr>
                <td><label for="password">Herhaal wachtwoord</label></td>
                <td><b-form-input id="password_check" type="password" v-model="passwordCheck" required /></td>
              </tr>
              <tr>
                <td></td>
                <td><b-button :disabled="!valid()" variant="primary" type="submit" @click.prevent="handleSubmit">Instellen</b-button></td>
              </tr>
            </table>
          </form>
        </div>

        <div v-if="success">
          Het aanpassen van het wachtwoord is gelukt. 
          <router-link :to="{ name: 'login' }">
            Je kunt nu hier inloggen.
          </router-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'PasswordReset',
    components: {
    },
    data () {
      return {
        password: '',
        passwordCheck: '',
        errorMessage: '',
        loading: true,
        verified: false,
        success: false,
      }
    },
    props: {
      resetKey: String,
      username: String,
    },
    methods: {
      valid () {
        return (this.password.length > 7 && this.password == this.passwordCheck)
      },
      handleSubmit () {
        if (this.password.length > 0) {
          this.loading = true

          this.$http.put('password-reset/'+ this.username +'/' + this.resetKey, {
            password: this.password
          })
          .then(() => {
            this.loading = false
            this.success = true
          })
          .catch(() => {
            this.loading = false
            this.errorMessage = 'Er is een onbekende fout opgetreden. Probeer de pagina opnieuw te laden.'
          });
        }
      }
    },
    mounted () {
      if (localStorage.getItem('jwt') != null) {
        // Logout first
        localStorage.removeItem('jwt')
        history.go()
      }

      // Check key
      this.$http.get('password-reset/'+ this.username +'/' + this.resetKey)
      .then(response => {
        this.user = response.data
        this.verified = true
        this.loading = false
      })
      .catch(() => {
        this.loading = false
        this.verified = false
      }); 
    }
  };
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  td:first-child {
    padding-right: 40px; 
  }


</style>